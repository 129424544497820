import styled from "styled-components";
import { Colors } from "../helpers/Colors";
import { Texts } from "../helpers/Texts";
import { getCurrentYear } from "../helpers/CurrentYearHelper";
import { useMemo } from "react";
import FooterLeftSideContent from "./FooterLeftSideContent";
import FooterCenterContent from "./FooterCenterContent";
import Anchor from "./Anchor";
import igMakeupLogoWhite from "../images/igMakeupLogoWhite.png";
import FooterRightSideContent from "./FooterRightSideContent";
import { useIsMobile } from "../hooks/useIsMobile";
import Line from "./Line";
import { yuseinWebsite } from "../helpers/Constants";

const Footer = () => {
  const currentYear = useMemo(() => getCurrentYear(), []);
  const copyright = `© ${currentYear} ${Texts.footerIGMakeUp}`;
  const isMobile = useIsMobile();

  return (
    <Wrapper>
      <InnerWrapper>
        <Image src={igMakeupLogoWhite} />
        <Line />
        <FooterContentWrapper $isMobile={isMobile}>
          <FooterLeftSideContent />
          <FooterCenterContent />
          <FooterRightSideContent />
        </FooterContentWrapper>
        <AnchorsWrapper>
          <Anchor>{copyright}</Anchor>
        </AnchorsWrapper>
      </InnerWrapper>
    </Wrapper>
  );
};

export default Footer;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${Colors.grayishPink};
  width: 100%;
  height: 100%;
`;

const InnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 32px;
  background-color: ${Colors.mediumBrown};
  width: 100%;
  border-radius: 16px 16px 0 0;
  padding: 32px;
`;

const AnchorsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const AnchorsInnerWrapper = styled.div`
  display: flex;
  gap: 2px;
`;

const FooterContentWrapper = styled.div<{ $isMobile: boolean }>`
  display: flex;
  width: ${({ $isMobile }) => !$isMobile && "100%"};
  flex-direction: ${({ $isMobile }) => $isMobile && "column"};
  justify-content: ${({ $isMobile }) =>
    $isMobile ? "center" : "space-evenly"};
  align-items: flex-start;
  flex-wrap: wrap;
  gap: ${({ $isMobile }) => ($isMobile ? "32px" : "16px")};
`;

const Image = styled.img`
  width: 150px;
`;
