import { memo, useEffect } from "react";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";

const AppInit = () => {
  useEffect(() => {
    dayjs.extend(advancedFormat);
  }, []);

  return null;
};

export default memo(AppInit);
