import styled from "styled-components";
import TextField from "@mui/material/TextField";
import TextContainer from "./TextContainer";
import { Texts } from "../helpers/Texts";
import { Colors } from "../helpers/Colors";
import Button from "./Button";
import { useForm } from "react-hook-form";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { nameRegex, phoneRegex } from "../helpers/Constants";
import { sendScheduleRequestEmail } from "../helpers/EmailJsHelper";
import { useRef, useState } from "react";
import { MAX_APP_WIDTH } from "../helpers/Values";
import { useIsMobile } from "../hooks/useIsMobile";
import BookingModal from "./BookingModal";
import { convertMonth } from "../helpers/MonthConverter";

const ScheduleRequestForm = () => {
  const [date, setDate] = useState<string | null>(null);
  const [shouldShowModal, setShouldShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSendEmailSuccessful, setIsSendEmailSuccessful] = useState(false);
  const handleOpenModal = () => setShouldShowModal(true);
  const handleCloseModal = () => setShouldShowModal(false);
  const nameRef = useRef<HTMLInputElement>(null);
  const phoneRef = useRef<HTMLInputElement>(null);
  const isMobile = useIsMobile();

  const onSubmit = (formData: any) => {
    setIsLoading(true);
    sendScheduleRequestEmail({
      ...formData,
      date: convertMonth(formData.date),
    })
      .then(() => {
        setIsSendEmailSuccessful(true);
      })
      .catch(() => {
        setIsSendEmailSuccessful(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
    setValue("name", "");
    setValue("phone", "");
    setValue("date", "");
    setDate(null);
    handleOpenModal();
    !!nameRef?.current && nameRef.current?.blur();
    !!phoneRef?.current && phoneRef.current?.blur();
  };

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  const handleDateChange = (value: any) => {
    setValue("date", dayjs(value).format("LL"));
    setDate(dayjs(value).format("LL"));
  };

  return (
    <Wrapper id="booking">
      {shouldShowModal && (
        <BookingModal
          isLoading={isLoading}
          isSendEmailSuccessful={isSendEmailSuccessful}
          shouldShowModal={shouldShowModal}
          handleCloseModal={handleCloseModal}
        />
      )}
      <InnerWrapper>
        <TextContainer text={Texts.scheduleRequestHeading} />
        <Form onSubmit={onSubmit}>
          <FormFieldsWrapper $isMobile={isMobile}>
            <TextField
              sx={{ width: "100%" }}
              inputRef={nameRef}
              {...register("name", {
                required: Texts.validationNameRequired,
                pattern: {
                  value: nameRegex,
                  message: Texts.validationNameInvalid,
                },
              })}
              error={!!errors.name}
              label={
                errors.name
                  ? errors.name.message?.toString()
                  : Texts.scheduleRequestName
              }
              variant="outlined"
            />
            <TextField
              sx={{ width: "100%" }}
              inputRef={phoneRef}
              {...register("phone", {
                required: Texts.validationPhoneRequired,
                pattern: {
                  value: phoneRegex,
                  message: Texts.validationPhoneInvalid,
                },
              })}
              label={
                errors.phone
                  ? errors.phone.message?.toString()
                  : Texts.scheduleRequestPhone
              }
              variant="outlined"
              error={!!errors.phone}
            />
            <DatePicker
              sx={{ width: "100%" }}
              {...register("date", { required: Texts.validationDateRequired })}
              disablePast
              value={date}
              slotProps={{
                textField: {
                  variant: "outlined",
                  error: !!errors.date && !watch("date"),
                },
              }}
              label={
                errors.date && !watch("date")
                  ? errors.date.message?.toString()
                  : Texts.scheduleRequestDate
              }
              onChange={handleDateChange}
            />
          </FormFieldsWrapper>
          <Button
            placeholder={Texts.scheduleSend}
            onClick={handleSubmit(onSubmit)}
            hasBackground
            alignSelf="flex-end"
          />
        </Form>
      </InnerWrapper>
    </Wrapper>
  );
};

export default ScheduleRequestForm;

const Wrapper = styled.div`
  display: flex;
  background-color: ${Colors.grayishPink};
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${Colors.white};
  background-color: ${Colors.grayishPink};
  max-width: ${MAX_APP_WIDTH};
  gap: 12px;
  padding: 48px 0;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 24px;
`;

const FormFieldsWrapper = styled.div<{ $isMobile: boolean }>`
  display: flex;
  width: 100%;
  flex-direction: ${({ $isMobile }) => $isMobile && "column"};
  justify-content: center;
  align-items: center;
  gap: 24px;
`;
