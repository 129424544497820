import { Texts } from "./Texts";

export const headSectionWelcomeTexts = [
  {
    text: Texts.headerWelcome,
    fontSize: "36px",
  },
  {
    text: Texts.headerDescription,
    fontSize: "24px",
  },
  {
    text: Texts.headerIvelinaGalenova,
    fontSize: "24px",
  },
];
