import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import { emailAddressMailTo, facebookLink, instagramLink } from "./Constants";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { SvgIconTypeMap } from "@mui/material/SvgIcon/SvgIcon";
import MailIcon from "@mui/icons-material/Mail";

export type SocialItem = {
  icon: OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
    muiName: string;
  };
  url: string;
};

export const socials: SocialItem[] = [
  {
    icon: FacebookIcon,
    url: facebookLink,
  },
  {
    icon: InstagramIcon,
    url: instagramLink,
  },
  {
    icon: MailIcon,
    url: emailAddressMailTo,
  },
];
