import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import TextContainer from "./TextContainer";
import styled from "styled-components";
import sentSuccessfully from "../images/sentSuccessfully.png";
import sentFailed from "../images/sentFailed.png";
import { getModalContentItems } from "../helpers/ModalHelper";
import { useMemo } from "react";
import { Hearts } from "react-loader-spinner";
import { Colors } from "../helpers/Colors";
import { Texts } from "../helpers/Texts";

type Props = {
  isLoading: boolean;
  isSendEmailSuccessful: boolean;
  shouldShowModal: boolean;
  handleCloseModal: () => void;
};

const BookingModal: React.FC<Props> = ({
  isLoading,
  isSendEmailSuccessful,
  shouldShowModal,
  handleCloseModal,
}) => {
  const textItems = useMemo(
    () => getModalContentItems(isSendEmailSuccessful),
    [isSendEmailSuccessful]
  );

  return (
    <Modal open={shouldShowModal} onClose={handleCloseModal} center>
      <InnerWrapper>
        {isLoading && (
          <>
            <TextContainer text={Texts.sending} fontSize="24px" />
            <Hearts height="80" width="80" color={Colors.mediumBrown} />
          </>
        )}
        {!isLoading && (
          <>
            <Image
              src={isSendEmailSuccessful ? sentSuccessfully : sentFailed}
            />
            {!!textItems?.length &&
              textItems.map(({ text, fontSize }) => (
                <TextContainer key={text} text={text} fontSize={fontSize} />
              ))}
          </>
        )}
      </InnerWrapper>
    </Modal>
  );
};

export default BookingModal;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 24px 0;
  gap: 12px;
`;

const Image = styled.img`
  width: 150px;
`;
