import styled from "styled-components";
import { FONT_SIZE_MAX } from "../helpers/Values";
import { Colors } from "../helpers/Colors";

export type TextType = {
  text: string;
  fontSize?: string;
  lineHeight?: string;
  color?: string;
};

const TextContainer: React.FC<TextType> = ({
  text,
  fontSize,
  lineHeight,
  color,
}) => (
  <TextWrapper $fontSize={fontSize} $lineHeight={lineHeight} $color={color}>
    {text}
  </TextWrapper>
);

export default TextContainer;

const TextWrapper = styled.h1<{
  $fontSize?: string;
  $lineHeight?: string;
  $color?: string;
  $smallSize?: boolean;
}>`
  font-size: ${({ $fontSize }) => ($fontSize ? $fontSize : FONT_SIZE_MAX)};
  line-height: ${({ $lineHeight }) => $lineHeight && $lineHeight};
  color: ${({ $color }) => ($color ? $color : Colors.mediumBrown)};
  margin: 0;
`;
