import styled from "styled-components";
import { Texts } from "../helpers/Texts";
import { Colors } from "../helpers/Colors";
import aboutMeImage from "../images/aboutMeImage.png";
import TextContainer from "./TextContainer";
import { useIsMobile } from "../hooks/useIsMobile";
import { COMMON_IMAGE_BORDER_RADIUS, MAX_APP_WIDTH } from "../helpers/Values";

const AboutMeSection = () => {
  const isMobile = useIsMobile();

  return (
    <Wrapper id="about">
      <InnerWrapper>
        <Image src={aboutMeImage} $isMobile={isMobile} />
        <TextWrapper $isMobile={isMobile}>
          <TextContainer text={Texts.aboutMeTitle} />
          <Paragraph
            dangerouslySetInnerHTML={{ __html: Texts.aboutMeDescription }}
            $isMobile={isMobile}
          />
        </TextWrapper>
      </InnerWrapper>
    </Wrapper>
  );
};

export default AboutMeSection;

const Wrapper = styled.div`
  position: relative;
  background-color: ${Colors.grayishPink};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 32px;
  width: 100%;
`;

const InnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: ${MAX_APP_WIDTH};
  gap: 64px;

  @media (max-width: 1100px) {
    flex-direction: column;
    justify-content: flex-start;
    gap: 32px;
  }
`;

const Image = styled.img<{ $isMobile: boolean }>`
  height: 100%;
  border-radius: ${COMMON_IMAGE_BORDER_RADIUS};
  width: ${({ $isMobile }) => $isMobile && "80%"};
`;

const TextWrapper = styled.div<{ $isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: ${({ $isMobile }) => $isMobile && "80%"};

  @media (max-width: 1100px) {
    align-items: ${({ $isMobile }) => !$isMobile && "center"};
  }
`;

const Paragraph = styled.p<{ $isMobile: boolean }>`
  color: ${Colors.mediumBrown};
  font-size: 18px;

  @media (max-width: 1100px) {
    width: ${({ $isMobile }) => !$isMobile && "60%"};
  }
`;
