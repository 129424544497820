import styled from "styled-components";
import TextContainer from "./TextContainer";
import { Texts } from "../helpers/Texts";
import { Colors } from "../helpers/Colors";
import Socials from "./Socials";
import { useIsMobile } from "../hooks/useIsMobile";

const FooterCenterContent = () => {
  const isMobile = useIsMobile();

  return (
    <Wrapper $isMobile={isMobile}>
      <TextContainer
        text={Texts.findMeAt}
        fontSize="24px"
        color={Colors.grayishPink}
      />
      <Socials bright />
    </Wrapper>
  );
};

export default FooterCenterContent;

const Wrapper = styled.div<{ $isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${({ $isMobile }) => ($isMobile ? "flex-start" : "center")};
  gap: 14px;
`;
