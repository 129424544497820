export const Texts = {
  navBarAbout: "За мен",
  navBarBook: "Запази час",
  navBarClients: "Клиенти",
  headerWelcome: "Добре дошли в IG Makeup!",
  headerDescription:
    "Започнете своето вълнуващо пътешествие и открийте магията на перфектния грим.",
  headerIvelinaGalenova: "- Ивелина Галенова",
  footerIvelinaGalenova: "Ивелина Галенова",
  footerMakeupArtist: "Гримьор",
  footerIGMakeUp: "IG Makeup",
  footerYuseinKerdakov: "Yusein Kerdakov",
  scheduleRequestHeading: "Запитване за час",
  scheduleRequestName: "Вашето име *",
  scheduleRequestPhone: "Телефон за връзка *",
  scheduleRequestDate: "Дата *",
  scheduleSend: "Изпрати",
  validationNameRequired: "Името е задължително",
  validationNameInvalid: "Невалидно име",
  validationPhoneRequired: "Телефонът е задължителен",
  validationPhoneInvalid: "Невалиден номер",
  validationDateRequired: "Датата е задължителна",
  aboutMeTitle: "За мен",
  aboutMeDescription:
    "Здравейте!<br /><br /> Аз съм Ивелина Галенова, а гримът е моят избран път.<br /><br /> Във всеки човек виждам възможността да се превърне в най-красивата версия на себе си и обожавам да помагам на клиентите си да се чувстват по-уверени и да блестят във важните си дни и специални поводи.<br /><br /> За мен е истинско удоволствие да творя със светлини и цветове, създавайки уникални гримове, които подчертават най-доброто във всяка жена.<br /><br /> Изграждането на вашата красота е моята страст, а вашата усмивка е моята награда.<br /><br />Благодаря Ви, че сте тук и добре дошли в моя малък свят на изкуството и красотата!",
  satisfiedClients: "Доволни клиенти",
  findMeAt: "Намери ме в мрежите",
  address: "ул. 'Перущица' 5<br /> гр. Костандово, България",
  orAtAddress: "Или на адрес",
  requestSentTitle: "Изпратено запитване",
  requestSentDescription: "Очаквайте обаждане от мен за доуточняване!",
  requestFailTitle: "Неуспешно запитване",
  requestFailDescription: "Нещо се обърка! Моля, опитайте отново по-късно!",
  requestSentThanks: "Благодаря Ви за проявения интерес!",
  sending: "Изпращане на запитване",
};
