import styled from "styled-components";
import { Colors } from "../helpers/Colors";
import { Texts } from "../helpers/Texts";
import TextContainer from "./TextContainer";

const FooterLeftSideContent = () => {
  return (
    <Wrapper>
      <TextContainer
        text={Texts.footerIvelinaGalenova}
        color={Colors.grayishPink}
        fontSize="24px"
      />
      <TextContainer
        text={Texts.footerMakeupArtist}
        color={Colors.grayishPink}
        fontSize="16px"
      />
    </Wrapper>
  );
};

export default FooterLeftSideContent;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 14px;
`;
