import styled from "styled-components";
import { useEffect, useMemo, useState } from "react";
import { Colors } from "../helpers/Colors";
import { Link } from "react-scroll";
import igMakeupLogo from "../images/igMakeupLogo.png";
import { useIsMobile } from "../hooks/useIsMobile";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import Line from "./Line";
import { scrollToTop } from "../helpers/ScrollToTopLogicHelper";
import { navBarItems } from "../helpers/NavBarHelper";

const NavBar = () => {
  const [shouldShowItems, setShouldShowItems] = useState(false);
  const _navBarItems = useMemo(() => navBarItems, []);
  const isMobile = useIsMobile();

  const handleShouldShowItems = () => setShouldShowItems(!shouldShowItems);

  useEffect(() => {
    const handleScroll = () => setShouldShowItems(false);

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Wrapper>
      <InnerWrapper $isMobile={isMobile} $isMenuOpened={shouldShowItems}>
        {shouldShowItems && isMobile && (
          <StyledCloseIcon onClick={handleShouldShowItems} />
        )}
        <Image src={igMakeupLogo} $isMobile={isMobile} onClick={scrollToTop} />
        {isMobile && shouldShowItems && <Line />}
        <LinksWrapper $isMobile={isMobile}>
          {!!_navBarItems?.length &&
            (!isMobile || shouldShowItems) &&
            _navBarItems.map(({ placeholder, linksTo, hasBackground }) => (
              <Link
                key={placeholder}
                to={linksTo}
                offset={-90}
                spy={true}
                smooth={true}
                duration={500}
              >
                <LinkStyles
                  $hasBackground={hasBackground}
                  onClick={isMobile ? handleShouldShowItems : undefined}
                >
                  {placeholder}
                </LinkStyles>
              </Link>
            ))}
          {isMobile && (
            <>
              {!shouldShowItems && (
                <StyledMenuIcon
                  onClick={handleShouldShowItems}
                  $isMobile={isMobile}
                />
              )}
            </>
          )}
        </LinksWrapper>
      </InnerWrapper>
    </Wrapper>
  );
};

export default NavBar;

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  display: flex;
  background-color: ${Colors.white};
  width: 100%;
  z-index: 999 !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
`;

const InnerWrapper = styled.div<{ $isMobile: boolean; $isMenuOpened: boolean }>`
  display: flex;
  flex-direction: ${({ $isMenuOpened, $isMobile }) =>
    $isMenuOpened && $isMobile && "column"};
  justify-content: ${({ $isMenuOpened, $isMobile }) =>
    $isMenuOpened && $isMobile ? "center" : "space-between"};
  align-items: center;
  width: 100%;
  padding: 12px 24px;
  gap: ${({ $isMenuOpened }) => $isMenuOpened && "18px"};
`;

const Image = styled.img<{ $isMobile: boolean }>`
  width: 150px;
  cursor: pointer;
`;

const LinksWrapper = styled.div<{ $isMobile: boolean }>`
  display: flex;
  flex-direction: ${({ $isMobile }) => $isMobile && "column"};
  justify-content: ${({ $isMobile }) => $isMobile && "center"};
  align-items: ${({ $isMobile }) => $isMobile && "center"};
  gap: 12px;
`;

const LinkStyles = styled.div<{ $hasBackground?: boolean }>`
  background: ${({ $hasBackground }) =>
    $hasBackground ? Colors.mediumBrown : "none"};
  color: ${({ $hasBackground }) =>
    $hasBackground ? Colors.white : Colors.mediumBrown};
  font-size: 20px;
  cursor: pointer;
  padding: 12px;

  &:hover {
    color: ${({ $hasBackground }) =>
      $hasBackground ? Colors.white : Colors.mediumBrownHover};
    background: ${({ $hasBackground }) =>
      $hasBackground ? Colors.mediumBrownHover : "none"};
  }
`;

const StyledMenuIcon = styled(MenuIcon)<{ $isMobile: boolean }>`
  color: ${Colors.mediumBrown};
`;

const StyledCloseIcon = styled(CloseIcon)`
  position: absolute;
  top: 12px;
  right: 12px;
  color: ${Colors.mediumBrown};
`;
