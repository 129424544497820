import React, { useState, useEffect } from "react";
import styled from "styled-components";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { scrollToTop } from "../helpers/ScrollToTopLogicHelper";
import {
  FULL_RADIUS,
  SCROLL_BUTTON_PADDING,
  SCROLL_BUTTON_POSITION_IN_PIXELS,
} from "../helpers/Values";
import { Colors } from "../helpers/Colors";
import { fadeIn, fadeOut } from "../helpers/ScrollToTopButtonHelper";

const ScrollToTopButton: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.scrollY > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  return (
    <ScrollButton onClick={scrollToTop} $isVisible={isVisible}>
      <ArrowUpwardIcon />
    </ScrollButton>
  );
};

export default ScrollToTopButton;

const ScrollButton = styled.button<{ $isVisible: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: ${SCROLL_BUTTON_POSITION_IN_PIXELS};
  right: ${SCROLL_BUTTON_POSITION_IN_PIXELS};
  display: ${({ $isVisible }) => ($isVisible ? "block" : "none")};
  padding: ${SCROLL_BUTTON_PADDING};
  background-color: ${Colors.mediumBrownHover};
  color: ${Colors.white};
  border: none;
  border-radius: ${FULL_RADIUS};
  cursor: pointer;
  transition: opacity 0.3s, transform 0.3s;
  z-index: 999;
  animation: ${({ $isVisible }) => ($isVisible ? fadeIn : fadeOut)} 0.4s
    ease-in-out;
  opacity: 0.8;

  &:hover {
    opacity: 0.6;
  }
`;
