import styled from "styled-components";
import NavBar from "./components/NavBar";
import ScrollToTopButton from "./components/ScrollToTopButton";
import HeadSection from "./components/HeadSection";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ScheduleRequestForm from "./components/ScheduleRequestForm";
import AppInit from "./components/AppInit";
import AboutMeSection from "./components/AboutMeSection";
import Brands from "./components/Brands";
import { scrollToTop } from "./helpers/ScrollToTopLogicHelper";
import { useEffect } from "react";
import ClientsSection from "./components/ClientsSection";
import Footer from "./components/Footer";

const App = () => {
  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Wrapper>
        <AppInit />
        <NavBar />
        <HeadSection />
        <Brands />
        <AboutMeSection />
        <Brands />
        <ClientsSection />
        <Brands />
        <ScheduleRequestForm />
        <Footer />
        <ScrollToTopButton />
      </Wrapper>
    </LocalizationProvider>
  );
};

export default App;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;
