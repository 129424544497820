export const convertMonth = (date: string) => {
  const Months = {
    January: "Януари",
    February: "Февруари",
    March: "Март",
    April: "Април",
    May: "Май",
    June: "Юни",
    July: "Юли",
    August: "Август",
    September: "Септември",
    October: "Октомври",
    November: "Ноември",
    December: "Декември",
  };

  const [month, day, year] = date.split(" ");
  const convertedMonth = Months[month as keyof typeof Months];

  return `${day} ${convertedMonth}, ${year}г`;
};
