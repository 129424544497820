import styled from "styled-components";
import { Colors } from "../helpers/Colors";

const Line = () => {
  return <StyledHR />;
};

export default Line;

const StyledHR = styled.hr`
  margin: 0;
  border: 1px dashed ${Colors.grayishPink};
  width: 95%;
`;
