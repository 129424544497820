import styled from "styled-components";
import { Colors } from "../helpers/Colors";
import { Texts } from "../helpers/Texts";
import TextContainer from "./TextContainer";
import Anchor from "./Anchor";
import { googleMapsAddress } from "../helpers/Constants";
import FmdGoodIcon from "@mui/icons-material/FmdGood";

const FooterRightSideContent = () => {
  return (
    <Wrapper>
      <TextContainer
        text={Texts.orAtAddress}
        color={Colors.grayishPink}
        fontSize="24px"
      />
      <Anchor href={googleMapsAddress}>
        <FmdGoodIcon />
        <Paragraph dangerouslySetInnerHTML={{ __html: Texts.address }} />
      </Anchor>
    </Wrapper>
  );
};

export default FooterRightSideContent;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 14px;
`;

const Paragraph = styled.p`
  margin-top: -2px;
  color: ${Colors.grayishPink};
  font-size: 16px;
  font-weight: bold;
`;
