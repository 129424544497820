import styled from "styled-components";
import { Colors } from "../helpers/Colors";
import { ReactNode } from "react";

type Props = {
  href?: string;
  children: ReactNode;
};

const Anchor: React.FC<Props> = ({ href, children }) => {
  return (
    <StyledAnchor href={href} target="_blank" rel="noreferrer">
      {children}
    </StyledAnchor>
  );
};

export default Anchor;

const StyledAnchor = styled.a<{ href?: string }>`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  color: ${Colors.grayishPink};
  gap: 2px;
  font-size: 12px;
  font-family: sans-serif;
  text-decoration: ${({ href }) => !href && "none"};

  ${({ href }) =>
    href &&
    `&:hover {
    color: ${Colors.grayishPinkHover};

    h1,
    p {
      color: ${Colors.grayishPinkHover};
    }
  }`}
`;
