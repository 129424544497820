import { Texts } from "./Texts";

export type NavBarItemType = {
  placeholder: string;
  linksTo: string;
  hasBackground?: boolean;
};

export const navBarItems: NavBarItemType[] = [
  {
    placeholder: Texts.navBarAbout,
    linksTo: "about",
  },
  {
    placeholder: Texts.navBarClients,
    linksTo: "clients",
  },
  {
    placeholder: Texts.navBarBook,
    linksTo: "booking",
    hasBackground: true,
  },
];
