import styled from "styled-components";
import { socials } from "../helpers/SocialLinksHelper";
import { Colors } from "../helpers/Colors";
import { useMemo } from "react";

type Props = {
  bright?: boolean;
};

const Socials: React.FC<Props> = ({ bright }) => {
  const socialLinks = useMemo(() => socials, []);

  return (
    <Wrapper>
      {!!socialLinks.length &&
        socialLinks.map(({ icon: Icon, url }) => (
          <Anchor
            key={url}
            href={url}
            target="_blank"
            rel="noreferrer"
            $bright={bright}
          >
            <Icon fontSize="large" />
          </Anchor>
        ))}
    </Wrapper>
  );
};

export default Socials;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
  color: ${Colors.mediumBrown};
`;

const Anchor = styled.a<{ $bright?: boolean }>`
  svg path {
    fill: ${({ $bright }) =>
      $bright ? Colors.grayishPink : Colors.mediumBrown};
  }

  &:hover {
    svg path {
      fill: ${({ $bright }) =>
        $bright ? Colors.grayishPinkHover : Colors.mediumBrownHover};
    }
  }
`;
