import lancomeImage from "../images/lancomeImage.svg";
import lorealImage from "../images/lorealImage.svg";
import hudaBeautyImage from "../images/hudaBeautyImage.svg";
import nyxImage from "../images/nyxImage.svg";
import cliniqueImage from "../images/cliniqueImage.svg";

export const brands = [
  { brandIcon: lancomeImage },
  { brandIcon: lorealImage },
  { brandIcon: hudaBeautyImage },
  { brandIcon: nyxImage },
  { brandIcon: cliniqueImage },
];
