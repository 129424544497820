import styled from "styled-components";
import { Colors } from "../helpers/Colors";

type Props = {
  placeholder: string;
  onClick: () => void;
  hasBackground?: boolean;
  alignSelf?: "flex-start" | "flex-end" | "center";
};

const Button: React.FC<Props> = ({
  placeholder,
  onClick,
  hasBackground,
  alignSelf,
}) => (
  <StyledButton
    onClick={onClick}
    $hasBackground={hasBackground}
    $alignSelf={alignSelf}
  >
    {placeholder}
  </StyledButton>
);

export default Button;

const StyledButton = styled.button<{
  $hasBackground?: boolean;
  $alignSelf?: "flex-start" | "flex-end" | "center";
}>`
  display: flex;
  align-self: ${({ $alignSelf }) => $alignSelf && $alignSelf};
  border: none;
  background: ${({ $hasBackground }) =>
    $hasBackground ? Colors.mediumBrown : "none"};
  color: ${({ $hasBackground }) =>
    $hasBackground ? Colors.white : Colors.mediumBrown};
  font-size: 20px;
  cursor: pointer;
  padding: 12px;

  &:hover {
    color: ${({ $hasBackground }) =>
      $hasBackground ? Colors.white : Colors.mediumBrownHover};
    background: ${({ $hasBackground }) =>
      $hasBackground ? Colors.mediumBrownHover : "none"};
  }
`;
