import styled from "styled-components";
import headSectionImage from "../images/headSectionImage.webp";
import { Colors } from "../helpers/Colors";
import TextContainer from "./TextContainer";
import Socials from "./Socials";
import { useMemo } from "react";
import { headSectionWelcomeTexts } from "../helpers/HeadSectionHelper";
import { MAX_APP_WIDTH } from "../helpers/Values";

const HeadSection = () => {
  const welcomeTexts = useMemo(() => headSectionWelcomeTexts, []);

  return (
    <Wrapper>
      <InnerWrapper>
        <WelcomeWrapper>
          {!!welcomeTexts?.length &&
            welcomeTexts.map(({ text, fontSize }) => (
              <TextContainer key={text} text={text} fontSize={fontSize} />
            ))}
          <Socials />
        </WelcomeWrapper>
        <Image src={headSectionImage} />
      </InnerWrapper>
    </Wrapper>
  );
};

export default HeadSection;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: ${Colors.grayishPink};
  overflow: hidden;
  padding: 0 32px;
`;

const InnerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0;
  width: 100%;
  max-width: ${MAX_APP_WIDTH};

  @media (max-width: 1100px) {
    flex-direction: column;
    padding-top: 136px;
    gap: 32px;
  }

  @media (max-width: 768px) {
    padding-top: 108px;
  }
`;

const WelcomeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 12px;
  padding: 0 32px;
`;

const Image = styled.img`
  right: 0;
  max-height: 100%;
  object-fit: cover;
`;
