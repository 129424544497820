import { Texts } from "./Texts";

export const getModalContentItems = (isSuccessful: boolean) => [
  {
    text: isSuccessful ? Texts.requestSentTitle : Texts.requestFailTitle,
    fontSize: "24px",
  },
  {
    text: isSuccessful
      ? Texts.requestSentDescription
      : Texts.requestFailDescription,
    fontSize: "16px",
  },
  {
    text: Texts.requestSentThanks,
    fontSize: "16px",
  },
];
