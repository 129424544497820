import { useMemo } from "react";
import { brands } from "../helpers/BrandsHelper";
import styled from "styled-components";
import { MAX_APP_WIDTH } from "../helpers/Values";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

const Brands = () => {
  const brandsItems = useMemo(() => brands, []);

  return (
    <Wrapper>
      <InnerWrapper>
        <Carousel
          autoPlay
          showStatus={false}
          showArrows={false}
          stopOnHover={false}
          infiniteLoop
          swipeable={false}
          showThumbs={false}
          width="80vw"
          preventMovementUntilSwipeScrollTolerance
          swipeScrollTolerance={50}
          showIndicators={false}
          interval={1800}
        >
          {brandsItems?.length
            ? brandsItems.map(({ brandIcon }) => (
                <Image key={brandIcon} src={brandIcon} />
              ))
            : undefined}
        </Carousel>
      </InnerWrapper>
    </Wrapper>
  );
};

export default Brands;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  max-width: ${MAX_APP_WIDTH};
  padding: 32px 0;

  .carousel .control-dots .dot {
    display: none;
  }
`;

const Image = styled.img`
  height: 100px;
`;
