import { useMemo } from "react";
import styled from "styled-components";
import { MAX_APP_WIDTH } from "../helpers/Values";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { Colors } from "../helpers/Colors";
import TextContainer from "./TextContainer";
import { Texts } from "../helpers/Texts";
import { useIsMobile } from "../hooks/useIsMobile";
import { clientsImages } from "../helpers/ClientsHelper";

const ClientsSection = () => {
  const isMobile = useIsMobile();
  const _clientsImages = useMemo(() => clientsImages, []);

  return (
    <Wrapper id="clients">
      <TextContainer text={Texts.satisfiedClients} />
      <InnerWrapper>
        <Carousel
          autoPlay
          showStatus={false}
          showArrows={!isMobile}
          stopOnHover={false}
          infiniteLoop
          swipeable={isMobile}
          showThumbs={false}
          width="80vw"
          preventMovementUntilSwipeScrollTolerance
          swipeScrollTolerance={50}
          interval={3500}
        >
          {_clientsImages?.length
            ? _clientsImages.map(({ image }) => (
                <Image key={image} src={image} />
              ))
            : undefined}
        </Carousel>
      </InnerWrapper>
    </Wrapper>
  );
};

export default ClientsSection;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${Colors.grayishPink};
  justify-content: center;
  align-items: center;
  padding: 32px;
  gap: 32px;
  text-align: center;

  @media (max-width: 430px) {
    gap: 0 !important;
  }
`;

const InnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  max-width: ${MAX_APP_WIDTH};

  .carousel {
    width: 100%;
    .control-prev.control-arrow:before {
      border-right: 8px solid ${Colors.mediumBrown};
    }

    .slider-wrapper {
      width: 100% !important;
    }

    .control-next.control-arrow:before {
      border-left: 8px solid ${Colors.mediumBrown};
    }

    button {
      &:hover {
        background: none !important;
      }
    }
  }
`;

const Image = styled.img`
  height: 500px;
  object-fit: contain;
`;
